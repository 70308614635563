export function getQsVar(field: string) {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(field) || undefined;
}

export function getElementById(id: string): HTMLInputElement | null {
	if (!id) {
		return null;
	}
	return document.getElementById(id) as HTMLInputElement;
}

/**
 * Safari doesn't handle input:focus styles well.
 * So we manually set our own .focus css class to keep focus styles
 * consistent accross browsers.
 *
 * You should call this for any input element that has
 * special focus styles.
 */
export function setFocusListeners(el: HTMLElement) {
	el.addEventListener("focus", (evt) => {
		addClass(el, "focus");
	});
	el.addEventListener("blur", (evt) => {
		removeClass(el, "focus");
	});
}

export function addClass(el: HTMLElement | null, className: string) {
	if (!el) return;
	el.classList.add(className);
}

export function removeClass(el: HTMLElement | null, className: string) {
	if (!el) return;
	el.classList.remove(className);
}

export function getIsStudent(): boolean {
	const student = getQsVar("student");
	return !!(student && (student === "1" || student === "true"));
}

export function getIsBaStudentPage() {
	const isStudent = getIsStudent();
	const isBa =
		getQsVar("application") === "ba" ||
		getQsVar("application") === "baclassroom";
	return isStudent && isBa;
}

/**
 * Adds the loginId to the url params if it exists.
 * paremterName defaults to 'prefill'
 */
export function addLoginIdToUrlParams(
	loginIdEl: HTMLInputElement | null,
	parameterName?: string
) {
	const urlParams = new URLSearchParams(window.location.search);

	if (!loginIdEl) {
		return urlParams;
	}

	const loginId = loginIdEl.value.trim();
	if (loginId) {
		urlParams.set(parameterName ?? "prefill", loginId);
	}
	return urlParams;
}

export function switchPage(path: string, customParams?: string) {
	if (!path) {
		return;
	}

	const urlParams = new URLSearchParams(window.location.search);
	let urlParamString = customParams || urlParams.toString();
	if (urlParamString) {
		urlParamString = "?" + urlParamString;
	}
	window.location.href = path + urlParamString;
}

export function showInputError(inputEl: HTMLInputElement, message: string) {
	if (getIsBaStudentPage()) {
		addClass(inputEl, "errored");
		showStudentPageResponse(message);
	} else {
		const parentEl = inputEl.parentElement;
		if (!parentEl) return;
		addClass(parentEl, "errored");
		const errorSpan = parentEl.getElementsByTagName("span")[0];
		if (errorSpan) {
			errorSpan.innerText = message;
		}
	}
}

export function removeInputError(inputEl: HTMLInputElement) {
	if (getIsBaStudentPage()) {
		const responseEl = getElementById("response-wrapper") as HTMLElement;
		removeClass(inputEl, "errored");
		responseEl.innerHTML = "";
		removeClass(responseEl, "error");
	} else {
		const parentEl = inputEl.parentElement;
		if (!parentEl) return;
		removeClass(parentEl, "errored");
	}
}

export function showStudentPageResponse(message: string, type?: string) {
	const responseEl = getElementById("response-wrapper");
	if (!responseEl) return;
	if (type === "success") {
		removeClass(responseEl, "error");
		addClass(responseEl, "success");
	} else {
		removeClass(responseEl, "success");
		addClass(responseEl, "error");
	}
	responseEl.innerHTML = message;
}

/**
 * Shows the error box near the top of the page for adult pages, or
 * below inputs on student pages
 *
 */
export function showError(message: string) {
	const isBaStudentPage = getIsBaStudentPage();
	if (isBaStudentPage) {
		showStudentPageResponse(message);
		return;
	}

	const errorEls = document.getElementsByClassName(
		"error-box"
	) as HTMLCollectionOf<HTMLElement>;

	if (!errorEls.length || !message) return;

	for (const errorEl of errorEls) {
		addClass(errorEl, "errored");
		errorEl.innerHTML = message;
	}
}

export function hideError() {
	if (getIsBaStudentPage()) {
		const responseEl = getElementById("response-wrapper");
		if (!responseEl) return;
		removeClass(responseEl, "error");
		removeClass(responseEl, "success");
		responseEl.innerHTML = "";
	} else {
		const errorEls = document.getElementsByClassName(
			"error-box"
		) as HTMLCollectionOf<HTMLElement>;

		if (!errorEls.length) return;

		for (const errorEl of errorEls) {
			removeClass(errorEl, "errored");
			errorEl.innerHTML = "";
		}
	}
}

export function changeDisplayOnSuccess() {
	const hideOnSuccessEls = document.getElementsByClassName("hide-on-success");
	Array.from(hideOnSuccessEls).forEach((el) => {
		(el as HTMLDivElement).style.display = "none";
	});
	const showOnSuccessEls = document.getElementsByClassName("show-on-success");
	Array.from(showOnSuccessEls).forEach((el) => {
		(el as HTMLDivElement).style.display = "block";
	});
}

// Determines if passed in loginId is an email
export function isValidEmail(loginId: string) {
	return (
		loginId && /^[A-Z0-9._%+\-]+@([A-Z0-9\-]+\.)+[A-Z]{2,}$/i.test(loginId)
	);
}

export function setupPageSwitchButton(
	id: string,
	page: string,
	customParams?: string
) {
	const buttonEl = getElementById(id);
	if (buttonEl) {
		buttonEl.addEventListener("click", (evt) => {
			evt.preventDefault();
			switchPage(page, customParams);
		});
	}
}

// Show the loading icon on an element if it's there.
export function showLoading(el: HTMLElement | null) {
	if (!el) return;
	if (getIsBaStudentPage()) {
		addClass(el, "no-click");
	} else {
		removeClass(el, "loaded");
		addClass(el, "loading");
	}
}

export function showLoaded(el: HTMLElement | null) {
	if (!el) return;
	if (getIsBaStudentPage()) {
		removeClass(el, "no-click");
	} else {
		removeClass(el, "loading");
		addClass(el, "loaded");
	}
}

// Hide the loading icon on an element if it's there.
export function hideLoading(el: HTMLElement | null) {
	if (!el) return;
	if (getIsBaStudentPage()) {
		removeClass(el, "no-click");
	} else {
		removeClass(el, "loading");
		removeClass(el, "loaded");
	}
}

export function getContactUsEmail(application?: string) {
	let email = "info@artofproblemsolving.com";
	if (application === "ba") {
		email = "info@beastacademy.com";
	} else if (application === "academy") {
		email = "info@aopsacademy.org";
	} else if (application === "academy-vc") {
		email = "virtual@aopsacademy.org";
	}

	return email;
}
